import Glide from "@glidejs/glide";

var glide = new Glide(".glide", {
  type: "carousel",
  focusAt: "center",
  gap: 0,
  perView: 1,
  autoplay: 5000,
  animationDuration: 1000,
});
glide.mount();
/*var glide = new Glide(".glide-mob", {
  type: "carousel",
  focusAt: "center",
  gap: 0,
  perView: 1,
  autoplay: 5000,
  animationDuration: 1000,
});
glide.mount();*/
